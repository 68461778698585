.App {
  /*text-align: center;*/
}

.navLink {
    color: white;
    text-decoration: none; /* no underline */
}

/* mouse over link */
.navLink:hover {
    color: #969295;
}

/* selected link */
.navLinkActive {
    color: #4BAF51;
}

.image-gallery-slide img {
    width: 100%;
    height: 500px;
}

.fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 10px 4px;
}

.fc .fc-daygrid-day-number {
    padding: 8px;
}

.fc .fc-toolbar-title {
    padding: 15px;
}

.fc .fc-button-group {
    padding: 10px;
}

.fc .fc-event-title {
    padding-top: 10px;
    height: 20px;
}

.fc .fc-event {
    transition: background-color 0.3s ease;
}

.fc .fc-event:hover {
    background-color: #1f5586;
}